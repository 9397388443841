import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/mnt/d/ShapeGanicGatsby/src/components/blog/layout.js";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import BlogOverview from "../../../components/blog/overview/blogOverview.mdx";
import AppImage from "../../../components/blog/appimage/appImage";
export const query = graphql`
  {
    mdx(slug: { eq: "calorie-counter-app-export-data/" }) {
      frontmatter {
        main_image {
          publicURL
        }
        image_introduction {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_introduction_alt
        image_introduction_desc
        image_introduction_credit_text
        image_introduction_credit_link
        image_first {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_first_alt
        image_second {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_second_alt
        image_third {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
        image_third_alt
        date(formatString: "MMMM D, YYYY")
      }
      fields {
        readingTime {
          minutes
        }
      }
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const SEO = makeShortcode("SEO");
const Title = makeShortcode("Title");
const Image = makeShortcode("Image");
const Link = makeShortcode("Link");
const AppPromotion = makeShortcode("AppPromotion");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO lang={props.pageContext.frontmatter.lang} description={props.pageContext.frontmatter.summary} title={props.pageContext.frontmatter.title} keywords={props.pageContext.frontmatter.keywords} imageUrl={props.data.mdx.frontmatter.main_image.publicURL} mdxType="SEO" />
    <Title title={props.pageContext.frontmatter.title} date={props.data.mdx.frontmatter.date} readingTime={props.data.mdx.fields.readingTime.minutes} mdxType="Title" />
    <p>
  <b>{props.pageContext.frontmatter.summary}</b> Do you track your meals and your body weight, did you create several recipes and added portion sizes to your favorite foods?
  This is valuable and painstakingly created data, the loss of which would be very painful for most of us. ShapeGanic now offers you the option of backing up your data manually.
  ShapeGanic uses an open file format for this and you can freely dispose of your backups.
    </p>
    <Image image={getImage(props.data.mdx.frontmatter.image_introduction)} imageAlt={props.data.mdx.frontmatter.image_introduction_alt} imageDesc={props.data.mdx.frontmatter.image_introduction_desc} imageAuthor={props.data.mdx.frontmatter.image_introduction_credit_text} imageLink={props.data.mdx.frontmatter.image_introduction_credit_link} mdxType="Image" />
    <h2>{`Calorie Counter Without Account: Data Protection Redefined`}</h2>
    <p>
  Time and again, the media report on successful hacker attacks on the servers of app companies.
  A popular competing{" "}
  <Link url={props.pageContext.frontmatter.myfitnesspal_url} mdxType="Link">
      {props.pageContext.frontmatter.myfitnesspal_text}
    </Link>{" "}
  company has also been affected in recent years.
  Sensitive information such as email addresses and passwords were captured and are now floating around on dubious platforms.
  But how can such a scenario and the resulting damage to the user be prevented in a sustainable and credible way?
    </p>
    <p>
  The answer is simple and effective: no data, no attack vector.
  For this reason, ShapeGanic was developed to be used exclusively <b>without an online account</b>.
  This means that your personal tracking data never leaves your device to be stored on potentially insecure servers.
  But what happens if, for example, you change your smartphone and want to transfer your data to another one?
  ShapeGanic provides you with a function for securely exporting and importing your data for this purpose.
    </p>
    <h3>{`One-Click Backup`}</h3>
    <p>
  The following illustration shows the overview of the settings and cross-app actions in ShapeGanic.
  With the <b>"Create backup"</b> action element, you can easily create a backup of your app data.
  By clicking on <b>"Read in backup"</b>, you have the option of restoring a previously created backup.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_first)} imageAlt={props.data.mdx.frontmatter.image_first_alt} mdxType="AppImage" />
    <p>
  When creating a data backup, you only need to confirm or adjust the file name and verify the storage location.
  The backup is created without any noticeable delay and you can then continue to use the app as usual.
    </p>
    <h2>{`Restore backup easily`}</h2>
    <p>
  You can easily restore a backup file by clicking on the <b>"Read in backup"</b> option in the menu shown above and then selecting the corresponding backup file.
  Alternatively, you can simply click on the backup file in a file explorer app.
  The ShapeGanic app or a dialog for selecting a suitable app will then open, here you must select <b>ShapeGanic</b>.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_second)} imageAlt={props.data.mdx.frontmatter.image_second_alt} mdxType="AppImage" />
    <p>
  In the view that now appears, you can select the data types you want to import.
  This function is shown in the figures above and below.
  Conveniently, the number of entries available in the backup file is shown for each data type.
  You can select or deselect all options by clicking on the top checkbox.
    </p>
    <AppImage image={getImage(props.data.mdx.frontmatter.image_third)} imageAlt={props.data.mdx.frontmatter.image_third_alt} mdxType="AppImage" />
    <p>
  By clicking on <b>"Import"</b> the data types you have selected will be imported after a short moment.
  Now all your tracked entries are available to you again!
  And don't forget to regularly back up your data in this way and save it in a safe place of your choice.
    </p>
    <h2>{`Automatic Backup of Your Data`}</h2>
    <p>
  On Android devices, you also have the option of setting up the automatic backup function of your system with{" "}
  <Link url={props.pageContext.frontmatter.google_one_url} mdxType="Link">
    {props.pageContext.frontmatter.google_one_text}
  </Link>
  . This regularly backs up the data of your apps installed on your device to Google Drive.
  If you install an app on another device that has been set up with the same Google account,
  or after you have previously uninstalled it on your current device, all your app data will be restored automatically.
  This mechanism also restores all your ShapeGanic entries.
    </p>
    <AppPromotion mdxType="AppPromotion" />
    <BlogOverview mdxType="BlogOverview" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      